import { FC, Suspense, lazy, useEffect, useRef, useState } from 'react';
import { Helmet } from 'react-helmet';
import {
  ISidebarDescriptor,
  SIDEBAR_PUSH_THRESHOLD_MEDIA_QUERY,
  SIDEBAR_TRANSITION,
  SIDEBAR_WIDTH,
} from './components/Sidebar';
import { QueryClientProvider } from 'react-query';
import { Route, Routes, useLocation } from 'react-router-dom';
import { makeClient } from './reactQuery';
import AppContext from './context';
import PageLoader from './components/PageLoader';
import mb from './utilities/mb';
import rem from './utilities/rem';
import styled, { css } from 'styled-components/macro';

const Authorize = lazy(
  () => import(/* webpackChunkName: "Authorize" */ './pages/Authorize')
);

const FacebookAccountAuthorization = lazy(
  () =>
    import(
      /* webpackChunkName: "FacebookAccountAuthorization" */ './pages/Accounts/Facebook/auth'
    )
);

const ForgotPassword = lazy(
  () =>
    import(/* webpackChunkName: "ForgotPassword" */ './pages/ForgotPassword')
);
const RestorePassword = lazy(
  () =>
    import(/* webpackChunkName: "RestorePassword" */ './pages/RestorePassword')
);
const Signup = lazy(
  () => import(/* webpackChunkName: "Signup" */ './pages/Signup')
);
const AppWrapper = lazy(
  () => import(/* webpackChunkName: "AppWrapper" */ './containers/App')
);

const Wrapper = styled.div`
  position: relative;
  overflow: hidden;
  display: flex;
  flex: 1 0 0;
  height: 100%;
`;

const Pushable = styled.div<{
  $isPushingFromLeft: boolean;
  $isPushingFromRight: boolean;
}>`
  display: flex;
  flex: 1 0 0;
  min-width: 0;
  transition: margin-right ${SIDEBAR_TRANSITION},
    margin-left ${SIDEBAR_TRANSITION};
  max-width: 100%;
  height: 100%;
  ${({ $isPushingFromRight }) =>
    $isPushingFromRight &&
    css`
      ${mb(SIDEBAR_PUSH_THRESHOLD_MEDIA_QUERY)} {
        margin-right: ${rem(SIDEBAR_WIDTH)};
      }
    `}
  ${({ $isPushingFromLeft }) =>
    $isPushingFromLeft &&
    css`
      ${mb(SIDEBAR_PUSH_THRESHOLD_MEDIA_QUERY)} {
        margin-left: ${rem(SIDEBAR_WIDTH)};
      }
    `}
`;

const App: FC = () => {
  const [authComponentLoaded, setAuthComponentLoaded] = useState(false);
  const [currentAppTitle, setCurrentAppTitle] = useState('');
  const [currentProfileTitle, setCurrentProfileTitle] = useState('');
  const [currentProfileWidgetId, setCurrentProfileWidgetId] = useState('');
  const [attachedSidebars, setAttachedSidebars] = useState<
    ISidebarDescriptor[]
  >([]);

  const leftSidebarPlaceholderRef = useRef<HTMLDivElement>(null);
  const rightSidebarPlaceholderRef = useRef<HTMLDivElement>(null);

  const [queryClient] = useState(() => makeClient());

  const location = useLocation();
  useEffect(() => {
    const opt: { top: number; behavior: ScrollBehavior } = {
      top: 0,
      behavior: 'smooth',
    };
    if (document.getElementById('scrollableContent')) {
      document.getElementById('scrollableContent')?.scrollTo(opt);
    } else {
      window.scrollTo(opt);
    }
  }, [location]);

  const attachSidebar = (sd: ISidebarDescriptor) => {
    setAttachedSidebars((prevState) => [...prevState, sd]);
  };

  const detachSidebar = (sd: ISidebarDescriptor) => {
    setAttachedSidebars((prevState) => prevState.filter((d) => d.id !== sd.id));
  };

  const updateSidebar = (sd: ISidebarDescriptor) => {
    setAttachedSidebars((prevState) =>
      prevState.map((d) => {
        if (sd.id === d.id) {
          return { ...d, ...sd };
        }
        return d;
      })
    );
  };

  const isPushingFromLeft = attachedSidebars
    .filter((sd) => !sd.modal && sd.position === 'left')
    .some((sd) => !!sd.opened);

  const isPushingFromRight = attachedSidebars
    .filter((sd) => !sd.modal && sd.position === 'right')
    .some((sd) => !!sd.opened);

  const providerValue = {
    authComponentLoaded,
    setAuthComponentLoaded,
    currentAppTitle,
    setCurrentAppTitle,
    currentProfileTitle,
    setCurrentProfileTitle,
    currentProfileWidgetId,
    setCurrentProfileWidgetId,
    attachSidebar,
    detachSidebar,
    updateSidebar,
    leftSidebarPlaceholderRef,
    rightSidebarPlaceholderRef,
    attachedSidebars,
  };

  return (
    <QueryClientProvider client={queryClient}>
      <AppContext.Provider value={providerValue}>
        <Helmet
          titleTemplate='%s | My Sportnet Online'
          defaultTitle='SportNet.online'
        />
        <Suspense fallback={<PageLoader />}>
          <Routes>
            <Route
              path='/auth/authorize/facebook'
              element={<FacebookAccountAuthorization />}
            />
            <Route path='/auth/authorize' element={<Authorize />} />
            <Route path='/signup' element={<Signup />} />
            <Route path='/forgot-password' element={<ForgotPassword />} />
            <Route path='/restore-password' element={<RestorePassword />}>
              <Route path=':token' element={<RestorePassword />} />
            </Route>
            <Route
              path='*'
              element={
                <Wrapper>
                  <div ref={providerValue.leftSidebarPlaceholderRef} />
                  <Pushable
                    $isPushingFromLeft={isPushingFromLeft}
                    $isPushingFromRight={isPushingFromRight}
                  >
                    <AppWrapper />
                  </Pushable>
                  <div ref={providerValue.rightSidebarPlaceholderRef} />
                </Wrapper>
              }
            />
          </Routes>
        </Suspense>
        <div id='loader' style={{ zIndex: -1 }}>
          <img
            alt='loader'
            src='data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4NCjwhLS0gR2VuZXJhdG9yOiBBZG9iZSBJbGx1c3RyYXRvciAyMi4xLjAsIFNWRyBFeHBvcnQgUGx1Zy1JbiAuIFNWRyBWZXJzaW9uOiA2LjAwIEJ1aWxkIDApICAtLT4NCjxzdmcgdmVyc2lvbj0iMS4xIiBpZD0iTGF5ZXJfMSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayIgeD0iMHB4IiB5PSIwcHgiDQoJIHZpZXdCb3g9IjAgMCAxMTEuOSAxMjIiIHN0eWxlPSJlbmFibGUtYmFja2dyb3VuZDpuZXcgMCAwIDExMS45IDEyMjsiIHhtbDpzcGFjZT0icHJlc2VydmUiPg0KPHN0eWxlIHR5cGU9InRleHQvY3NzIj4NCgkuc3Qwe2ZpbGw6I0Y5MjUzOTt9DQoJLnN0MXtmaWxsOiNGRkZGRkY7fQ0KPC9zdHlsZT4NCjxwYXRoIGNsYXNzPSJzdDAiIGQ9Ik0xMDMuMiw5Ny45YzQuOC0yLjcsOC43LTkuNCw4LjctMTQuOVYzOWMwLTUuNS0zLjktMTIuMi04LjctMTQuOUw2NC42LDJDNTkuOC0wLjcsNTItMC43LDQ3LjIsMmwtMzguNSwyMg0KCUMzLjksMjYuOCwwLDMzLjUsMCwzOXY0NGMwLDUuNSwzLjksMTIuMiw4LjcsMTQuOWwzOC41LDIyYzQuOCwyLjcsMTIuNiwyLjcsMTcuNCwwTDEwMy4yLDk3Ljl6Ii8+DQo8cGF0aCBjbGFzcz0ic3QxIiBkPSJNODAuOSw0My45Yy0wLjYsMC4yLTEuMSwwLjQtMS41LDAuN0w1OS4zLDU2LjFjLTMuMywxLjktNS44LDYuMy01LjgsMTBsMCwyNy4yYy0wLjMtMC4xLTAuNi0wLjItMC44LTAuMw0KCUwzMi41LDgxLjVjLTIuNS0xLjQtNC41LTQuOS00LjUtNy44VjUwLjhjMC0yLjgsMi02LjMsNC41LTcuOGwyMC4xLTExLjVjMi41LTEuNCw2LjYtMS40LDkuMSwwTDgxLjksNDNjMC4yLDAuMSwwLjQsMC40LDAuNiwwLjUNCglMODAuOSw0My45eiBNODYuNCw3My43YzAsMi44LTIsNi4zLTQuNSw3LjhMNjEuNyw5Mi45Yy0wLjksMC41LTIuMSwwLjgtMy4zLDAuOVY2Ni4xYzAtMS45LDEuNi00LjYsMy4zLTUuNmwyMC4xLTExLjUNCgljMC40LTAuMiwxLjEtMC42LDItMC44aDIuMWMwLjMsMC44LDAuNSwxLjcsMC41LDIuNVY3My43eiIvPg0KPC9zdmc+DQo='
          />
        </div>
      </AppContext.Provider>
    </QueryClientProvider>
  );
};

export default App;
